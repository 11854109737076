<template>
<!--  -->
	<div>
		<div class="container">
			<div class="dataItem darkBack" v-for="(item,index) in list" :key="index">
				<div class="lightBlueFont">{{item.name}}</div>
				<div class="blueFont font31" style="margin-top:0.04rem;">
					<countTo :startVal='0' :endVal='item.num' :duration='3000'></countTo>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {getAjax, timestamp} from "/src/assets/js/websocket";
	// 引入数字滚动组件
import countTo from 'vue-count-to';
  export default {
    components: { countTo },
		data() {
			return{
				list:[]
			}
		},
		methods:{
			// 获取数据
			getData(){
				let that = this;
				var data = {
                        token: window.getToken(),
                        app_id: window.appId,
                        time: timestamp(),
                    }
                    getAjax({
                        url: "/map/screen/getHomeSportsman",
                        method:"GET",
                        data:data,
                        success:function(res){
                            // console.log(res)
							that.list=res.data.area_list
                        },
                    })
			}
		},
		mounted() {
			this.getData();
		}
	}
</script>

<style scoped>
	.font31{font-size: 0.31rem}
	.container{
		width: 5.96rem;
		height: 3.81rem;
		position: relative;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.dataItem{
		width: 2.86rem;
		height: 1.06rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
</style>